import styled, { keyframes, css } from "styled-components"
import React, { useEffect, useRef, useState } from "react"

interface FadeInProps {
  offset?: number
  delay?: number
}

const FadeIn: React.FC<FadeInProps> = (props) => {
  const offset = typeof props.offset === 'number' ? props.offset : 0
  const delay = typeof props.delay === 'number' ? props.delay : 0
  const domRef = useRef<HTMLDivElement>(null)

  const [isVisible, setIsVisible] = useState(false)

  const fadeInIfNeeded = () => {
    const dom = domRef.current
    if (!dom) return
    const top = dom.getBoundingClientRect().top
    if (document.body.clientHeight - top > offset) {
      setTimeout(() => {
        setIsVisible(true)
      }, delay)
    }
  }

  const handleScroll = () => {
    fadeInIfNeeded()
  }

  useEffect(() => {
    fadeInIfNeeded()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Root ref={domRef} isVisible={isVisible} delay={delay}>{props.children}</Root>
  )
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const Root = styled.div<{ isVisible: boolean, delay: number }>`
  visibility: hidden;
  animation-duration: 0.7s;
  ${(props) => props.isVisible && css`
    visibility: visible;
    animation-name: ${fadeInAnimation};
  `}
`

export default FadeIn
