import React from "react"
import styled from "styled-components"

const Footer: React.FC = () => (
  <Root />
)

const Root = styled.br`
  @media ${(props) => props.theme.minMdUp} {
    display: none;
  }
`

export default Footer
