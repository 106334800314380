import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

interface SEOProps {
  title?: string
  description?: string
  imageUrl?: string
  pathname: string
}

interface SiteMetadataQuery {
  site: {
    siteMetadata: {
      name: string
      url: string
      description: string
    }
  }
}

const SEO: React.FC<SEOProps> = ({ title, description, imageUrl, pathname }) => {
  const {
    site: {
      siteMetadata: {
        name: siteName,
        url: siteURL,
        description: siteDescription
      }
    }
  } = useStaticQuery<SiteMetadataQuery>(
    graphql`
      query {
        site {
          siteMetadata {
            name
            url
            description
          }
        }
      }
    `
  )
  const renderedTitle = title ? `${title} | ${siteName}` : siteName

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="株式会社ゼンアーキテクツ,ゼンアーキテクツ,ゼン アーキテクツ,（株）ゼンアーキテクツ,(株)ゼンアーキテクツ,㈱ゼンアーキテクツ,ゼン,zenarchitects,zenarchitects.co.jp,zenarchitects.jp,ZEN Architects,ZEN,ソフトウェアエンジニアリング,アーキテクチャ,アーキテクティング,Architecture,Architecting,三宅和之,三宅,MIYAKE,kazuyukimiyake,Kazuyuki Miyake,Microsoft Azure,Azure,CosmosDB,AppService,Azure Functions,PaaS,サーバーレス,Serverless,クラウドネイティブ,Hack,Hackathon,ハッカソン,ディシプリンド・アジャイル・デリバリー,Disciplined Agile Delivery,DAD,エンタープライズアジャイル,エンタープライズ アジャイル,Enterprise Agile"
      />
      <meta property="fb:app_id" content="1203360669778533" />
      <meta property="og:title" content={renderedTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${siteURL}${pathname}`} />
      <meta property="og:image" content={imageUrl ?? `${siteURL}/ogp.png`} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description || siteDescription} />
      <meta name="twitter:site" content="@zenarchitects" />
      <meta name="twitter:card" content="summary" />
      <title>{renderedTitle}</title>
    </Helmet>
  )
}

export default SEO
