import React from "react"
import styled from "styled-components"
import { PageRendererProps, navigate } from "gatsby"
import headerBackgroundImage from "@/images/zen-advisor-header.jpg"
import aboutImage from "@/images/zen-advisor-about.png"
import msPartnerLogoImage from "@/images/ms-partner-logo-color.png"
import endorsementBackgroundImage from "@/images/zen-advisor-endorsement-bg.jpg"
import logbuildLogo from "@/images/logbuild.png"
import chudenLogo from "@/images/chuden-logo.jpg"
import hololabLogo from "@/images/hololab-logo.png"
import doubleQuoteOpen from "@/images/double-quote-open.png"
import doubleQuoteClose from "@/images/double-quote-close.png"
import FadeIn from "@/components/FadeIn"
import MobileBreak from "@/components/MobileBreak"
import SEO from "@/components/SEO"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

type ZenAdvisorPageProps = PageRendererProps

const title = 'ZEN Advisor'
const description = 'Zen Advisorとは、Azureスペシャリストによる技術サポートサービスです。フォーラム形式でお客様のAzureに関するお困りごと、お悩み解決をサポートします'

const ContactButton: React.FC = () => (
  <Contact onClick={() => navigate('/contacts?advisor=true')}>お問い合わせはこちら</Contact>
)

const ProblemBlock: React.FC<{ text: string }> = ({ text }) => (
  <Problem>
    <ProblemIcon>
      <FontAwesomeIcon icon={faCheckCircle} />
    </ProblemIcon>
    {text}
  </Problem>
)

const ZenAdvisor: React.FC<ZenAdvisorPageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <Header>
        <Background></Background>
        <Content>
          <FadeIn offset={0} delay={200}>
            <TitleMain>
              ZEN Advisor
            </TitleMain>
            <TitleSub>
              Microsoft ゴールドパートナーによる Azure サポート
            </TitleSub>
          </FadeIn>
          <FadeIn offset={0} delay={400}>
            <ContactButton />
          </FadeIn>
        </Content>
      </Header>
      <AboutSection>
        <AboutContent>
          <AboutText>
            <AboutTitle>ZEN Advisor とは</AboutTitle>
            <AboutDescription>
              Azure スペシャリストによる QA サポートです。フォーラム形式でお客様のお困りごと、お悩み解決をサポートします。お客様の抱える疑問や課題をぜひご相談ください。弊社のスペシャリストが最適なサポートをご提供いたします。
            </AboutDescription>
          </AboutText>
          <AboutImageWrapper>
            <AboutImage src={aboutImage} />
          </AboutImageWrapper>
        </AboutContent>
      </AboutSection>
      <ProblemSection>
        <ProblemTitle>こういったことに<MobileBreak />お困りのお客様へ</ProblemTitle>
        <Problems>
          {ProblemBlock({ text: '自分たちだけで作っているが、社内に Azure の知見がない' })}
          {ProblemBlock({ text: '経営層から次々と依頼がくるものの、調査する時間がない' })}
          {ProblemBlock({ text: 'Azure をとりあえず導入したが、もっと有効に活用したい' })}
        </Problems>
      </ProblemSection>
      <PartnerSection>
        <PartnerContent>
          <PartnerImageWrapper>
            <PartnerImage src={msPartnerLogoImage} />
          </PartnerImageWrapper>
          <PartnerText>
            <PartnerTitle>信頼の Microsoft ゴールドパートナー</PartnerTitle>
            <PartnerDescription>ゼンアーキテクツは、 Microsoft ゴールドパートナー（Gold Cloud Platform コンピテンシー取得）です。 Azure 黎明期より PaaS / Serverless 技術に特化したエンタープライズ向けクラウドアプリケーションの導入支援・ DevOps サポートを実施してきました。 現在ではその実績と活動内容をご評価いただき、プロジェクト支援だけでなく Microsoft 公式イベント やコミュニティにおいても Microsoft Azure の技術リーダーとして幅広く貢献しています。</PartnerDescription>
          </PartnerText>
        </PartnerContent>
      </PartnerSection>
      <ServiceSection>
        <ServiceHeader>
          <ServiceHeaderTitle>ZEN Advisor <MobileBreak />サービス概要</ServiceHeaderTitle>
          <ServiceHeaderDescription>
            Microsoft Azure を中心とするクラウド環境や GitHub に代表される DevOps 技術の活用について、<br />
            お客様プロジェクトで発生した疑問や課題を、スペシャリストが解決をサポートします。
          </ServiceHeaderDescription>
        </ServiceHeader>
        <ServiceContent>
          <Services>
            <Service>
              <ServiceTitle>Azure 技術情報の提供</ServiceTitle>
              <ServiceDescription>進化する技術の情報を集めるための時間がない、キャッチアップするのが難しい。そういったお悩みをお持ちの方へ、日々アップデートされる Azure の更新情報や新技術への情報アクセスをサポートいたします。</ServiceDescription>
            </Service>
            <Service>
              <ServiceTitle>Azure に関する技術サポート</ServiceTitle>
              <ServiceDescription>ZEN Advisor では、専用のサポートポータルを通じて Azure に関する技術サポートをご提供します。本番環境でのトラブルや調査が必要な際は、ゴールドパートナーである弊社からマイクロソフトへのサービスリクエストの発行を代行することで、早期の課題解決も期待できます。</ServiceDescription>
            </Service>
            <Service>
              <ServiceTitle>サンプル提供やコードレビュー</ServiceTitle>
              <ServiceDescription>ご質問の内容によっては、サンプルコードを添えて回答をさせていただき、問題をより迅速に解決します。また、サンプルコードを参考にお客様が作成したソースを Azure スペシャリストの観点でコードレビューを行うことも可能です。</ServiceDescription>
            </Service>
          </Services>
        </ServiceContent>
        <ServiceBottom>
          <ServiceConditionTitle>サービス提供条件</ServiceConditionTitle>
          <ServiceConditions>
            <ServiceCondition>上記の価格は、弊社がリセラーとして提供する Azure サブスクリプションにご加入いただくことが条件となります。</ServiceCondition>
            <ServiceCondition>サービス対応は最大月 5 時間までの稼働を想定しています。</ServiceCondition>
            <ServiceCondition>対応時間は平日 10:00 - 18:00、初動までの時間は 6 営業時間以内です。</ServiceCondition>
            <ServiceCondition>調査に時間を要するもの、追加検証が必要な内容については、対応できない場合があります。</ServiceCondition>
            <ServiceCondition>お客様の Azure リソースの調査などの対応は、弊社提供のサブスクリプションご利用が条件です。</ServiceCondition>
            <ServiceCondition>本番環境のインシデント対応は、マイクロソフトへのサービスリクエスト発行代行までとさせていただきます。</ServiceCondition>
            <ServiceCondition>稼働時間が月間 5 時間を超える場合に関しましては、別途契約変更が必要です。</ServiceCondition>
            <ServiceCondition>設計書やマニュアルなどドキュメント作成は含まれません。</ServiceCondition>
          </ServiceConditions>
          <ServiceNote>24/365 対応や稼働時間の拡大など、上記の枠を越える対応に関しては別プラン・別途お見積もりとなります。お気軽にご相談ください。</ServiceNote>
        </ServiceBottom>
      </ServiceSection>
      <EndorsementSection>
        <h2 className="title">導入事例</h2>
        <div className="endorsements">
          <div className="endorsement">
            <div className="corporate">
              <img src={logbuildLogo} className="logo" />
              <div className="person">
                <div className="position">
                  <p>株式会社log build</p>
                  <p>CTO</p>
                </div>
                <div className="name">森貞 英彦 様</div>
              </div>
            </div>
            <div className="text">
              360度で表示される現場写真は、もともと4MB程度ある1枚の画像データ。そのまま表示させると速度が非常に遅くなるので、
              高速にアクセスできるようなファイル形式に変換します。
              しかし、これをお客さまが1日に100〜1,000枚単位で撮影する際、どのように並列処理するとパフォーマンスがあがるのか試行錯誤を繰り返していました。
              それが ZEN ARCHITECTS さんの助言によって、少しチューニングしただけでも100倍以上に速度が上がったんです。<br />
              <br />
              ただ既存の仕組みを流用するだけなら他の基盤でも良いのですが、開発をメインに考えるならば、
              Azure が最適なんじゃないかと感じています。改良に対して『絶対不可能はない』と感じられるのは、
               ZEN ARCHITECTS さんのようなエキスパートがいるから。こうした点でもマイクロソフトのパートナーシップをもっともっと活用しようと思います。
            </div>
          </div>
          <div className="endorsement">
            <div className="corporate">
              <img src={chudenLogo} className="logo" />
              <div className="person">
                <div className="position">
                  <p>中部電力株式会社</p>
                  <p>ITシステムセンター デジタル化推進G</p>
                </div>
                <div className="name">近藤 正大 様</div>
              </div>
            </div>
            <div className="text">
              中部電力では、「作る」から「使う」へといった発想で、迅速かつ高品質なシステム構築を実現できるAzureの活用を推進しています。
              単なる「利用」のレベルを超えてAzure本来のポテンシャルを生かしていくため、専業のエキスパートであるゼンアーキテクツへ支援を依頼いたしました。
              抽象的なパブリッククラウドの一般論を紹介してくれるコンサルタントは多いですが、具体的なコードレベルで、かつ、実開発プロジェクトのサポートを受けられるサービスは、本当に貴重です。
              ZEN Advisorでは、DevOpsを実現する上でのパイプラインの改善・高度化（サンプルコードの提供含む）をはじめとした、多種多様なリクエストに応えていただき、得られた知見はプロジェクト成功に欠かせないものでした。
              続々とサービスが追加され進化し続けるプラットフォームであるAzureを使いこなすために、強力なソリューションであるZEN Advisorの活用をおすすめします。
            </div>
          </div>
          <div className="endorsement">
            <div className="corporate">
              <img src={hololabLogo} className="logo" />
              <div className="person">
                <div className="position">
                  <p>株式会社ホロラボ</p>
                  <p>代表取締役 CEO, Co-Founder</p>
                </div>
                <div className="name">中村 薫 様</div>
              </div>
            </div>
            <div className="text">
              ホロラボでは Mixed Reality を先駆的に行う企業として、様々な企業に技術支援を行っております。
              技術支援を行う上で効率良くお客様とコミュニケーションする方法として、ZEN Advisor のプラットフォーム自体を、弊社のQ&Aツールとして活用しております。
              質問を横断的に検索ができるためそのままストック情報として扱うことが可能な部分、また、他のコミュニケーションツールの場合、内部と外部のコミュニケーションを別チャネルや別トピックに分けなければならず内容が分散してしまう傾向があるのに対して、ZEN Advisor は一つの質問スレッド内で内部用と外部用のスレッドが作成されるので、情報を一元的に管理ができるように運用ができる部分が助かっています。
              弊社のようにお客様と技術支援のコミュニケーションを効率良く実施したい場合は ZEN Advisor をQ&A プラットフォームとして活用することをお勧めします。
            </div>
          </div>
        </div>
      </EndorsementSection>
      <ContactSection>
        <ContactTitle>まずはお気軽に<MobileBreak />お問い合わせください</ContactTitle>
        <ContactButton />
      </ContactSection>
    </Root>
  </>
)

const Root = styled.div``

const Header = styled.div`
  position: relative;
  background-color:#4e969e;
  overflow: hidden;

  @media ${(props) => props.theme.minMdUp} {
    height: 600px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 400px;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${headerBackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(80%) contrast(150%) blur(4px);
  transform: scale(1.1);
`

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TitleMain = styled.h1`
  font-family: 'Teko', sans-serif;
  text-align: center;
  color: ${(props) => props.theme.textLightColor};
  line-height: 1;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 90px;
    margin-top: 50px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 60px;
    margin-top: 45px;
  }
`

const TitleSub = styled.p`
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 1.5;

  @media ${(props) => props.theme.minMdUp} {
    margin-bottom: 80px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin: 10px 0 40px;
    width: 16em;
  }
`

const Contact = styled.button`
  background: ${(props) => props.theme.secondaryLightColor};
  color: ${(props) => props.theme.primaryDarkColor};
  border-radius: 40px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 15px 50px;
    font-size: 24px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 15px 30px;
    font-size: 18px;
  }
`

const AboutSection = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};
  overflow: hidden;

  @media ${(props) => props.theme.minMdUp} {
    height: 500px;
  }
`

const AboutContent = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;

  @media ${(props) => props.theme.maxSmDown} {
    flex-direction: column;
  }
  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxMdDown} {
    padding-right: 15px;
    padding-left: 15px;
    width: 750px;
  }
  @media ${(props) => props.theme.minLgUp} and ${(props) => props.theme.maxLgDown} {
    padding-right: 15px;
    padding-left: 15px;
    width: 970px;
  }
  @media ${(props) => props.theme.minXLUp} {
    padding-right: 15px;
    padding-left: 15px;
    width: 1170px;
  }
`

const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.minMdUp} {
    padding: 0 50px;
    width: 60%;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 50px;
    padding: 0 30px;
  }
`

const AboutTitle = styled.h2`
  font-weight: 300;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 48px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    font-size: 30px;
  }
`

const AboutDescription = styled.p`
  line-height: 1.75;
  text-align: justify;

  @media ${(props) => props.theme.minMdUp} {
    max-width: 720px;
    margin-top: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 20px;
  }
`

const AboutImageWrapper = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    width: 40%;
    padding-top: 40px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin: 30px 0 40px;
  }
`
const AboutImage = styled.img`
  @media ${(props) => props.theme.minMdUp} {
    position: relative;
    left: -40px;
    height: 110%;
  }
  @media ${(props) => props.theme.maxSmDown} {
    width: 100%;
  }
`

const ProblemSection = styled.section`
  background-color: ${(props) => props.theme.primaryColor};
  text-align: center;
  color: #fff;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0;
  }
  @media ${(props) => props.theme.maxSmDown} {
    padding: 60px 30px;
  }
`

const ProblemTitle = styled.h2`
  font-weight: 300;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    font-size: 24px;
  }
`

const Problems = styled.div`
  text-align: left;

  @media ${(props) => props.theme.minMdUp} {
    margin: 60px auto 0;
    width: 580px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 30px;
  }
`

const Problem = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 20px;
  }

  & + & {
    margin-top: 10px;
  }
`

const ProblemIcon = styled.span`
  font-size: 40px;
  margin-right: 16px;
`

const PartnerSection = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0;
  }
`

const PartnerContent = styled.div`
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  padding: 80px;

  @media ${(props) => props.theme.maxSmDown} {
    flex-direction: column;
    padding: 40px 30px 60px;
  }
  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxMdDown} {
    width: 750px;
  }
  @media ${(props) => props.theme.minLgUp} and ${(props) => props.theme.maxLgDown} {
    width: 970px;
  }
  @media ${(props) => props.theme.minXLUp} {
    width: 1170px;
  }
`

const PartnerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.minMdUp} {
    width: 40%;
  }
`

const PartnerImage = styled.img`
  @media ${(props) => props.theme.minMdUp} {
    width: 400px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    width: 100%;
  }
`

const PartnerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${(props) => props.theme.minMdUp} {
    width: 60%;
  }
`

const PartnerTitle = styled.h2`
  font-weight: 300;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 24px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }
`

const PartnerDescription = styled.p`
  line-height: 1.75;
  text-align: justify;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 20px;
  }
`

const ServiceSection = styled.section`
  background: ${(props) => props.theme.secondaryLightColor};
`

const ServiceHeader = styled.div`
  text-align: center;
  background-color: #409EA6;
  color: #fff;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0 200px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px;
  }
`

const ServiceHeaderTitle = styled.h2`
  font-weight: 300;
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 40px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    font-size: 30px;
  }
`

const ServiceHeaderDescription = styled.p`
  margin: 20px auto 0;
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    text-align: center;
  }
  @media ${(props) => props.theme.maxSmDown} {
    text-align: justify;
  }
`

const ServiceContent = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    margin-bottom: -60px;
  }
`

const Services = styled.div`
  margin: 0 auto;
  display: flex;

  @media ${(props) => props.theme.maxSmDown} {
    flex-direction: column;
    background: ${(props) => props.theme.primaryDarkColor};
    color: #fff;
    padding: 20px 0;
  }
  @media ${(props) => props.theme.minMdUp} {
    transform: translateY(-140px);
  }
  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxMdDown} {
    width: 750px;
  }
  @media ${(props) => props.theme.minLgUp} and ${(props) => props.theme.maxLgDown} {
    width: 970px;
  }
  @media ${(props) => props.theme.minXLUp} {
    width: 1170px;
  }
`

const Service = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    padding: 40px 30px;
    color: #fff;
    background: ${(props) => props.theme.primaryDarkColor};
    width: 33.3%;
    margin: 0 16px;
    border-radius: 4px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px;
    position: relative;
  }

  & + &:before {
    @media ${(props) => props.theme.maxSmDown} {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 1px;
      background-color: rgba(255,255,255,0.4);
    }
  }
`

const ServiceTitle = styled.h3`
  font-weight: 300;
  text-align: center;
  font-size: 20px;

  @media ${(props) => props.theme.minMdUp} {
    margin-bottom: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-bottom: 40px;
  }
`

const ServiceDescription = styled.p`
  text-align: justify;
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 16px;
  }
`

const ServiceBottom = styled.div`
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    padding-bottom: 60px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px;
  }
`

const ServiceConditionTitle = styled.h3`
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    font-size: 16px;
    margin-bottom: 30px;
  }
`

const ServiceConditions = styled.ul`
  display: inline-block;

  @media ${(props) => props.theme.maxSmDown} {
    padding-left: 16px;
    font-size: 14px;
  }
`

const ServiceCondition = styled.li`
  text-align: justify;
  padding: 2px 0;
`

const ServiceNote = styled.p`
  @media ${(props) => props.theme.minMdUp} {
    margin-top: 20px;
    text-align: center;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 30px;
    text-align: justify;
    font-size: 14px;
  }
`

const EndorsementSection = styled.section`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${endorsementBackgroundImage});

  @media ${(props) => props.theme.minMdUp} {
    background-position: center;
  }

  @media ${(props) => props.theme.maxSmDown} {
    background-position: bottom center;
  }

  .title {
    text-align: center;
    background-color: #5A74A8;
    color: #fff;
    font-weight: 300;
    position: relative;

    @media ${(props) => props.theme.minMdUp} {
      font-size: 30px;
      padding: 20px 0;
    }

    @media ${(props) => props.theme.maxSmDown} {
      font-size: 20px;
      padding: 14px 0;
    }

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(90, 116, 168, 0);
      border-top-color: #5A74A8;

      @media ${(props) => props.theme.minMdUp} {
        border-width: 16px;
        margin-left: -16px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }

  .endorsements {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.minMdUp} {
      padding: 100px 0;
    }

    @media ${(props) => props.theme.maxSmDown} {
      padding: 40px 0;
    }
  }

  .endorsement {
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;

    @media ${(props) => props.theme.minMdUp} {
      padding: 60px 80px 60px 60px;
      width: 80%;
      max-width: 1070px;
    }

    @media ${(props) => props.theme.maxMdDown} {
      flex-direction: column;
    }

    @media ${(props) => props.theme.maxSmDown} {
      margin: 0 30px;
      padding: 30px 30px 50px;
    }

    @media ${(props) => props.theme.minLgUp} {
      &:nth-child(even) {
        flex-direction: row-reverse;
        padding: 60px 60px 60px 80px;

        .corporate {
          margin-right: 0;
          margin-left: 50px;
        }
      }
    }

    & + .endorsement {
      @media ${(props) => props.theme.minMdUp} {
        margin-top: 80px;
      }

      @media ${(props) => props.theme.maxSmDown} {
        margin-top: 30px;
      }
    }

    .corporate {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media ${(props) => props.theme.minLgUp} {
        margin-right: 50px;
      }

      @media ${(props) => props.theme.maxMdDown} {
        margin-bottom: 30px;
      }

      .logo {
        @media ${(props) => props.theme.minMdUp} {
          width: 260px;
        }

        @media ${(props) => props.theme.maxSmDown} {
          width: 160px;
        }
      }

      .person {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media ${(props) => props.theme.minLgUp} {
          margin-top: 30px;
        }

        @media ${(props) => props.theme.maxMdDown} {
          margin-top: 20px;
        }

        .position {
          font-size: 14px;
          margin-bottom: 5px;
          color: ${(props) => props.theme.secondaryDarkColor};
        }

        .name {
          color: ${(props) => props.theme.primaryDarkColor};
          font-size: 18px;
          letter-spacing: 0.1em;
          font-weight: 600;
        }
      }
    }

    .text {
      text-align: justify;
      line-height: 1.75;
      position: relative;

      @media ${(props) => props.theme.maxSmDown} {
        font-size: 14px;
      }

      &:before, &:after {
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media ${(props) => props.theme.minMdUp} {
          width: 30px;
          height: 30px;
        }

        @media ${(props) => props.theme.maxSmDown} {
          width: 20px;
          height: 20px;
        }
      }

      &:before {
        background-image: url(${doubleQuoteOpen});

        @media ${(props) => props.theme.minMdUp} {
          left: -35px;
          top: -10px;
        }

        @media ${(props) => props.theme.maxSmDown} {
          top: -20px;
          left: -10px;
        }
      }

      &:after {
        background-image: url(${doubleQuoteClose});

        @media ${(props) => props.theme.minMdUp} {
          right: -35px;
          bottom: -10px;
        }

        @media ${(props) => props.theme.maxSmDown} {
          bottom: -20px;
          right: -10px;
        }
      }

      p + p {
        margin-top: 10px;
      }
    }
  }
`

const ContactSection = styled.section`
  background: linear-gradient(to left,#409EA6 0%,#2C6C8B 51%,#203F56 100%);
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 0;
  }
  @media ${(props) => props.theme.maxSmDown} {
    padding: 60px 30px;
  }
`

const ContactTitle = styled.p`
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;
`

export default ZenAdvisor
